<!-- eslint-disable vue/no-v-html -->
<template>
<div class="tab-pane active show p-3" style="margin-top: -12px;">
    <div class="kt-font-lg kt-font-bold pb-3">
        <SVGIcon
            style="width: 24px; height: 24px;"
            :hex-color="'#007bff'"
            :name="'chart-bar'"
            class="kt-svg-icon mr-3"
        />
        Chart Settings
    </div>

    <div class="form-group row pt-4">
        <div class="col-lg-6">
            <label>Chart Type:</label>
            <select class="form-control">
                <option>Stacked Bar Chart</option>
                <option>Stacked Radial Chart</option>
            </select>
        </div>
        <div class="col-lg-6">
            <label class="">Stack By:</label>
            <select class="form-control">
                <option>Ethnicity</option>
                <option>Gender</option>
                <option>Ell Status</option>
                <option>Language Awarded</option>
            </select>
        </div>
    </div>

    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
    <!-- Add header -->
    <div class="kt-font-lg kt-font-bold mb-3">
        <SVGIcon
            style="width: 24px; height: 24px;"
            :hex-color="'#007bff'"
            :name="'settings-3'"
            class="kt-svg-icon mr-3"
        />
        Add data point for comparison
    </div>

    <!-- Regional picker -->
    <ul class="nav nav-tabs nav-tabs-line mb-0 ml-1">
        <li
            v-for="tab in tabs"
            :key="tab.name"
            class="nav-item"
        >
            <a
                class="nav-link"
                :class="{active: tab.active}"
                data-toggle="tab"
                href="#"
                role="tab"
                @click.stop.prevent="selectTab(tab.name)"
            >
                {{ tab.name }}
            </a>
        </li>
    </ul>
    <!-- search box for adding data point -->
    <div class="kt-section pb-0 mb-0">
        <div v-if="items.length" class="kt-section__body row">
            <div class="col-5">
                <div class="form-group pb-0 pt-3">
                    <select
                        v-model="schoolYear"
                        class="form-control kt-font-bolder"
                    >
                        <option
                            v-for="y in schoolYears"
                            :key="y.value"
                            :value="y.value"
                        >
                            {{ y.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-7">
                <div class="form-group pb-0 pt-3">
                    <div :key="`type_${activeTab.name}_${key}`" class="kt-input-icon kt-input-icon--left kt-input-icon--right">
                        <vue-typeahead-bootstrap
                            v-model="addSearch"
                            :data="items"
                            :serializer="item => `${item.name}`"
                            :show-on-focus="true"
                            :placeholder="`Search ${activeTab.name}`"
                            @hit="addDataPoint($event)"
                        >
                            <template
                                slot="suggestion"
                                slot-scope="{ data }"
                            >
                                <div class="kt-widget4 mb-3 pt-3">
                                    <div class="kt-widget4__item">
                                        <div class="kt-widget4__pic kt-widget4__pic--pic teacher-dropdown">
                                            <a class="kt-media kt-media--sm">
                                                <div class="kt-badge kt-badge--lg kt-badge--success">
                                                    {{ data.name.substring(0, 2) }}
                                                </div>
                                            </a>
                                        </div>
                                        <div class="kt-widget4__info">
                                            <a class="kt-widget4__username">
                                                {{ data.name }}
                                            </a>
                                            <p class="kt-widget4__text">
                                                {{ activeTab.name }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </vue-typeahead-bootstrap>

                        <span
                            style="z-index: 4;"
                            class="kt-input-icon__icon kt-input-icon__icon--left"
                        >
                            <span>
                                <i class="la la-search" />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg" />

    <!-- Current Comparisons -->
    <div class="kt-font-lg kt-font-bold mb-3">
        <SVGIcon
            style="width: 24px; height: 24px;"
            :hex-color="'#007bff'"
            :name="'combo'"
            class="kt-svg-icon mr-3"
        />
        Current Comparisons
    </div>

    <!-- List comparisons -->
    <div class="kt-widget4 mb-3 pt-3">
        <div
            v-for="dataPoint in dataPoints"
            :key="`${activeTab.name}_${dataPoint.name}`"
            class="kt-widget4__item"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <span>
                    <div class="kt-badge kt-badge--lg kt-badge--success">
                        {{ dataPoint.name.substring(0, 2) }}
                    </div>
                </span>
            </div>
            <div class="kt-widget4__info">
                <a
                    href="#"
                    class="kt-widget4__username"
                    @click.stop.prevent=""
                >
                    {{ dataPoint.name }}
                </a>
                <p class="kt-widget4__text">
                    School Year: {{ dataPoint.schoolYear }} - {{ dataPoint.schoolYear + 1 }}
                </p>
            </div>
            <a
                href="#"
                class="btn btn-sm btn-icon-sm"
                @click.stop.prevent="removeDataPoint(dataPoint)"
            >
                <i class="la la-close" />
            </a>
        </div>
    </div>

    <div class="w-100 pt-5">
        <button
            type="button"
            class="btn btn-label-primary btn-lg kt-font-lg btn-upper w-100"
            @click="close"
        >
            Execute Comparison
        </button>
    </div>
</div>
</template>

<script>

// import async from 'async';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Types from '../../store/Types';
// import * as network from '../../network';

export default {
    name: 'DashboardAddComparePanel',
    components: {
        VueTypeaheadBootstrap,
    },
    data() {
        return {
            addSearch: null,
            key: 0,
            tabs: [{
                name: 'Big 5',
                active: true,
            }, {
                name: 'Regions',
                active: false,
            }, {
                name: 'Districts',
                active: false,
            }, {
                name: 'Schools',
                active: false,
            }],
        };
    },
    computed: {
        schoolYear: {
            set(schoolYear) {
                this.$store.commit(Types.mutations.SET_BILITERACY_SEAL_SCHOOL_YEAR, schoolYear);
            },
            get() {
                return this.$store.state.biliteracySeal.schoolYear;
            },
        },
        schoolYears() {
            return [{
                value: 2021,
                text: '2021 - 2022',
            }, {
                value: 2020,
                text: '2020 - 2021',
            }, {
                value: 2019,
                text: '2019 - 2020',
            }];
        },
        activeTab() {
            return this.tabs.find((tab) => tab.active);
        },
        dataPoints() {
            return this.$store.state.biliteracySeal.dataPoints;
        },
        items() {
            const { activeTab } = this;
            let output = [];
            if (activeTab.name == 'Big 5') output = [...this.$store.state.biliteracySeal.big5];
            if (activeTab.name == 'Regions') output = [...this.$store.state.biliteracySeal.regions];
            if (activeTab.name == 'Districts') output = [...this.$store.state.biliteracySeal.districts];
            if (activeTab.name == 'Schools') output = [...this.$store.state.biliteracySeal.schools];
            return output
                .map((p, idx) => ({ name: p, type: activeTab.name, id: `${idx}` }));
        },
    },
    mounted() {

    },
    methods: {
        close() {
            this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: null,
            });
        },
        removeDataPoint(dataPoint) {
            this.$store.commit(Types.mutations.REMOVE_BILTERACY_DATA_POINT, dataPoint);
        },
        addDataPoint(p) {
            const dataPoint = { ...p, schoolYear: this.schoolYear };
            dataPoint.id = `${p.id}_${this.schoolYear}`;
            this.$store.commit(Types.mutations.ADD_BILTERACY_DATA_POINT, dataPoint);
            setTimeout(() => {
                this.addSearch = null;
                this.key += 1;
            }, 10);
        },
        selectTab(tabName) {
            const tabs = this.tabs.map((t) => {
                const tab = { ...t };
                tab.active = t.name == tabName;
                return tab;
            });
            this.tabs = tabs;
        },
    },

};

</script>

<style scoped>

pre {
  white-space: normal !important;
  font-size: 100% !important;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
}

</style>
