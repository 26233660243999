var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("close"),
      _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
        _vm.isPanel
          ? _c(
              "div",
              { staticClass: "tab-content p-3 mt-0" },
              [
                _vm.panel == "student" ? _c("StudentDetailsPanel") : _vm._e(),
                _vm.panel == "cw" ? _c("CourseAssignmentPanel") : _vm._e(),
                _vm.panel == "gb-settings"
                  ? _c("GradebookSettingsPanel")
                  : _vm._e(),
                _vm.panel == "communication"
                  ? _c("CommunicationPanel")
                  : _vm._e(),
                _vm.panel == "dashboard"
                  ? _c("DashboardAddComparePanel")
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }