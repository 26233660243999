<template>
<div class="tab-pane active show ">
    <div
        v-if="assignment"
        class="kt-widget28__tab-items"
    >
        <div class="kt-widget28__tab-item pt-2 pb-2 kt-widget28__tab">
            <span>
                Assignment Details
            </span>
        </div>

        <h5 class="kt-font-lg font Black mt-4 mb-0 ml-3 pb-4 pt-2">
            {{ assignment.courseWorkTitle }}
        </h5>

        <div class="row px-4 py-2">
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Course</span>
                    <span>{{ course.name }}</span>
                </div>
            </div>
            <div class="col-6">
                <div
                    v-if="teacher"
                    class="kt-widget28__tab-item"
                >
                    <span>Teacher</span>
                    <span>{{ teacher.lastName }}</span>
                </div>
            </div>
        </div>

        <div class="row px-4 py-2">
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Marking Period</span>
                    <span>{{ markingPeriod.markingPeriod }}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Grading Category</span>
                    <span>{{ assignment.categoryName }}</span>
                </div>
            </div>
        </div>

        <div class="row px-4 py-2">
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Published</span>
                    <span>{{ assignment.publishDate }}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Due</span>
                    <span>{{ assignment.dueDate }}</span>
                </div>
            </div>
        </div>

        <div class="row px-4 py-2">
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Points</span>
                    <span>{{ assignment.maxPoints }}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="kt-widget28__tab-item">
                    <span>Weight</span>
                    <span>{{ assignment.courseWorkWeight }}</span>
                </div>
            </div>
        </div>
        <div class="row px-4 py-2">
            <div
                v-if="assignment.courseWorkDomain == 'Google'"
                class="col-12"
            >
                <a
                    v-if="assignment.alternateLink && assignment.googleCourseWorkId"
                    :href="assignment.alternateLink"
                    target="_blank"
                    class="btn btn-google pull-right"
                >
                    <img
                        class="gc"
                        src="/images/google_classroom_mono.svg"
                    >
                    Open In Google
                </a>
                <router-link
                    v-if="$_userMixins_isSchoolUser && assignment.associatedWithDeveloper"
                    class="btn btn-primary pull-right mr-2"
                    :to="{
                        name: 'TeacherGoogleCourseAssignmentEdit',
                        params: {
                            googleCourseWorkId: assignment.googleCourseWorkId,
                            extCourseSectionId: course.extCourseSectionId,
                            schoolEmail: teacher.schoolEmail,
                        }
                    }"
                >
                    <img
                        class="gc"
                        src="/images/sync-grades_mono.svg"
                    >
                    <span style="color: #fff">
                        Edit In SyncGrades
                    </span>
                </router-link>
            </div>
            <div
                v-else-if="$_userMixins_isSchoolUser && teacher && teacher.schoolEmail == $_userMixins_schoolEmail"
                class="col-12"
            >
                <router-link
                    class="btn btn-primary pull-right mr-2"
                    :to="{
                        name: 'TeacherLocalCourseAssignmentEdit',
                        params: {
                            courseWorkId: assignment.courseWorkId,
                            extCourseSectionId: course.extCourseSectionId,
                        }
                    }"
                >
                    <img
                        class="gc"
                        src="/images/sync-grades_mono.svg"
                    >
                    <span style="color: #fff">
                        Edit In SyncGrades
                    </span>
                </router-link>
            </div>
        </div>
        <div class="row px-4 py-2">
            <div class="col-12 pt-3 markdown-container">
                <VueShowdown
                    v-if="assignment.courseWorkDescription"
                    :markdown="assignment.courseWorkDescription"
                    flavor="github"
                    class="markdown"
                    :options="{ emoji: true }"
                />
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Vue from 'vue';
// @ts-ignore
import { VueShowdown } from 'vue-showdown';
import { courseWorkMixins } from '../../store/mixins/courseWorkMixins';
import teacherMixins from '../../store/mixins/teacherMixins';
import userMixins from '../../store/mixins/userMixins';
import { getCourses, CourseFilter } from '../../store/mixins/courseMixins';

export default Vue.extend({
    name: 'CourseAssignmentPanel',
    components: {
        VueShowdown,
    },
    mixins: [
        courseWorkMixins,
        teacherMixins,
        userMixins,
    ],
    computed: {
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        linkingGuid() {
            return this.$route.query.linkingGuid;
        },
        googleCourseWorkId() {
            return this.$route.query.googleCourseWorkId;
        },
        courseWorkId() {
            return this.$route.query.courseWorkId;
        },
        courseWorkDomain() {
            return this.$route.query.courseWorkDomain;
        },
        filteredCourseWork() {
            const { courseWorkId, googleCourseWorkId, linkingGuid } = this;
            const { database } = this.$store.state;
            if (courseWorkId || this.courseWorkDomain == 'Local') {
                if (courseWorkId) return database.courseWork.filter((cw) => cw.courseWorkId == courseWorkId);
                if (linkingGuid) return database.courseWork.filter((cw) => cw.linkingGuid == linkingGuid);
            }
            if (googleCourseWorkId || this.courseWorkDomain == 'Google') {
                if (googleCourseWorkId) return database.googleCourseWork.filter((cw) => cw.googleCourseWorkId == googleCourseWorkId);
                if (linkingGuid) return database.googleCourseWork.filter((cw) => cw.linkingGuid == linkingGuid);
            }
            return [];
        },
        assignment() {
            const [first] = this.filteredCourseWork;
            return first || null;
        },
        courseSections() {
            const { database } = this.$store.state;
            return this.filteredCourseWork.map((cw) => {
                const { courseSectionId } = cw;
                const courses = getCourses(database, new CourseFilter({ courseSectionId }));
                const [course] = courses;
                return course || null;
            });
        },
        course() {
            const [course] = this.courseSections;
            return course || null;
        },
        courseSectionId() {
            const { course } = this;
            return course ? course.courseSectionId : null;
        },

        gradingTemplate() {
            return this.course.gradeTemplate;
        },
        markingPeriod() {
            const { markingPeriods } = this.$store.state.database;
            const { schoolTermMarkingPeriodId } = this.assignment;
            return markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
    },
    mounted() {

    },
    methods: {

    },
});

</script>

<style scoped>
div.panel-body {
    height: 100vh;
}

div.bottom-bar {
    position: fixed;
    bottom: 0;
    /* transform: translateY(100%); */
    border-top: 1px solid #ebedf2;
}

div.gradepanel-button {
    position: fixed;
    top: 1.6%;
    right: 14%;
}

.one-line {
    line-height: 2rem;
    max-height: 2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

img.gc {
    width: 20px;
}

.normal-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .kt-widget28__tab-items .kt-widget28__tab-item:last-child {
    border-bottom: 1px solid #ebedf2;
}

.kt-widget28__tab-item {
    overflow: hidden;
}

.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .row .col-6 {
    align-content: stretch;
    display: flex;
    justify-content: stretch;
}

.kt-widget28 .kt-widget28__wrapper .tab-content .tab-pane .row .col-6 .kt-widget28__tab-item {
    width: 100%;
}

span.sg-error-message {
    display: block;
    font-size: 0.8rem;
    color: red;
}
</style>
