<template>
<div>
    <slot name="close" />
    <div class="kt-portlet__body kt-portlet__body--fit">
        <div
            v-if="isPanel"
            class="tab-content p-3 mt-0"
        >
            <StudentDetailsPanel v-if="panel == 'student'" />
            <CourseAssignmentPanel v-if="panel == 'cw'" />
            <GradebookSettingsPanel v-if="panel == 'gb-settings'" />
            <CommunicationPanel v-if="panel == 'communication'" />
            <!-- <AiPanel v-if="panel == 'ai'" /> -->
            <DashboardAddComparePanel v-if="panel == 'dashboard'" />
        </div>
    </div>
</div>
</template>

<script>

import CourseAssignmentPanel from '../../../components/QuickPanel/CourseAssignmentPanel.vue';
import StudentDetailsPanel from '../../../components/QuickPanel/StudentDetailsPanel.vue';
import GradebookSettingsPanel from '../../../components/QuickPanel/GradebookSettingsPanel.vue';
import CommunicationPanel from '../../../components/QuickPanel/CommunicationPanel.vue';
// import AiPanel from '../../../components/QuickPanel/AiPanel.vue';
import DashboardAddComparePanel from '../../../components/QuickPanel/DashboardAddComparePanel.vue';

// import GoogleCoursePanel from '../../../components/QuickPanel/GoogleCoursePanel.vue';
// import TeacherDetailsPanel from '../../../components/QuickPanel/TeacherDetailsPanel.vue';
// import CourseSectionPanel from '../../../components/QuickPanel/CourseSectionPanel/CourseSectionPanel.vue';
// import AttendancePanel from '../../../components/QuickPanel/AttendancePanel/AttendancePanel.vue';
// import StudentSubmissionsPanel from '../../../components/QuickPanel/StudentSubmissionsPanel/StudentSubmissionsPanel.vue';

export default {
    name: 'TheQuickPanel',
    components: {
        StudentDetailsPanel,
        CourseAssignmentPanel,
        GradebookSettingsPanel,
        CommunicationPanel,
        DashboardAddComparePanel,
        // AiPanel,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        showQuickPanel() {
            return this.$store.state.quickPanel.open;
        },
        panel() {
            const { isPanel } = this;
            if (!isPanel) return null;
            const { query } = this.$store.state.route;
            return query.panel;
        },
        isPanel() {
            const { query } = this.$store.state.route;
            return query && query.panel;
        },
    },
};

</script>

<style scoped>
.kt-quick-panel-overlay {
    cursor: pointer;
}
</style>
