var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-pane active show p-3",
      staticStyle: { "margin-top": "-12px" },
    },
    [
      _c(
        "div",
        { staticClass: "kt-font-lg kt-font-bold pb-3" },
        [
          _c("SVGIcon", {
            staticClass: "kt-svg-icon mr-3",
            staticStyle: { width: "24px", height: "24px" },
            attrs: { "hex-color": "#007bff", name: "chart-bar" },
          }),
          _vm._v(" Chart Settings "),
        ],
        1
      ),
      _vm._m(0),
      _c("div", {
        staticClass:
          "kt-separator kt-separator--border-dashed kt-separator--space-lg",
      }),
      _c(
        "div",
        { staticClass: "kt-font-lg kt-font-bold mb-3" },
        [
          _c("SVGIcon", {
            staticClass: "kt-svg-icon mr-3",
            staticStyle: { width: "24px", height: "24px" },
            attrs: { "hex-color": "#007bff", name: "settings-3" },
          }),
          _vm._v(" Add data point for comparison "),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "nav nav-tabs nav-tabs-line mb-0 ml-1" },
        _vm._l(_vm.tabs, function (tab) {
          return _c("li", { key: tab.name, staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: tab.active },
                attrs: { "data-toggle": "tab", href: "#", role: "tab" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.selectTab(tab.name)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tab.name) + " ")]
            ),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "kt-section pb-0 mb-0" }, [
        _vm.items.length
          ? _c("div", { staticClass: "kt-section__body row" }, [
              _c("div", { staticClass: "col-5" }, [
                _c("div", { staticClass: "form-group pb-0 pt-3" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.schoolYear,
                          expression: "schoolYear",
                        },
                      ],
                      staticClass: "form-control kt-font-bolder",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.schoolYear = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.schoolYears, function (y) {
                      return _c(
                        "option",
                        { key: y.value, domProps: { value: y.value } },
                        [_vm._v(" " + _vm._s(y.text) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "form-group pb-0 pt-3" }, [
                  _c(
                    "div",
                    {
                      key: `type_${_vm.activeTab.name}_${_vm.key}`,
                      staticClass:
                        "kt-input-icon kt-input-icon--left kt-input-icon--right",
                    },
                    [
                      _c("vue-typeahead-bootstrap", {
                        attrs: {
                          data: _vm.items,
                          serializer: (item) => `${item.name}`,
                          "show-on-focus": true,
                          placeholder: `Search ${_vm.activeTab.name}`,
                        },
                        on: {
                          hit: function ($event) {
                            return _vm.addDataPoint($event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "suggestion",
                              fn: function ({ data }) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget4 mb-3 pt-3" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget4__item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-widget4__pic kt-widget4__pic--pic teacher-dropdown",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-media kt-media--sm",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-badge kt-badge--lg kt-badge--success",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.name.substring(
                                                              0,
                                                              2
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "kt-widget4__info" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-widget4__username",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "kt-widget4__text",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.activeTab.name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          572217823
                        ),
                        model: {
                          value: _vm.addSearch,
                          callback: function ($$v) {
                            _vm.addSearch = $$v
                          },
                          expression: "addSearch",
                        },
                      }),
                      _vm._m(1),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", {
        staticClass:
          "kt-separator kt-separator--border-dashed kt-separator--space-lg",
      }),
      _c(
        "div",
        { staticClass: "kt-font-lg kt-font-bold mb-3" },
        [
          _c("SVGIcon", {
            staticClass: "kt-svg-icon mr-3",
            staticStyle: { width: "24px", height: "24px" },
            attrs: { "hex-color": "#007bff", name: "combo" },
          }),
          _vm._v(" Current Comparisons "),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "kt-widget4 mb-3 pt-3" },
        _vm._l(_vm.dataPoints, function (dataPoint) {
          return _c(
            "div",
            {
              key: `${_vm.activeTab.name}_${dataPoint.name}`,
              staticClass: "kt-widget4__item",
            },
            [
              _c(
                "div",
                { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                [
                  _c("span", [
                    _c(
                      "div",
                      {
                        staticClass: "kt-badge kt-badge--lg kt-badge--success",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(dataPoint.name.substring(0, 2)) + " "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "kt-widget4__info" }, [
                _c(
                  "a",
                  {
                    staticClass: "kt-widget4__username",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(dataPoint.name) + " ")]
                ),
                _c("p", { staticClass: "kt-widget4__text" }, [
                  _vm._v(
                    " School Year: " +
                      _vm._s(dataPoint.schoolYear) +
                      " - " +
                      _vm._s(dataPoint.schoolYear + 1) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "a",
                {
                  staticClass: "btn btn-sm btn-icon-sm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.removeDataPoint(dataPoint)
                    },
                  },
                },
                [_c("i", { staticClass: "la la-close" })]
              ),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "w-100 pt-5" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-label-primary btn-lg kt-font-lg btn-upper w-100",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [_vm._v(" Execute Comparison ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group row pt-4" }, [
      _c("div", { staticClass: "col-lg-6" }, [
        _c("label", [_vm._v("Chart Type:")]),
        _c("select", { staticClass: "form-control" }, [
          _c("option", [_vm._v("Stacked Bar Chart")]),
          _c("option", [_vm._v("Stacked Radial Chart")]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-6" }, [
        _c("label", {}, [_vm._v("Stack By:")]),
        _c("select", { staticClass: "form-control" }, [
          _c("option", [_vm._v("Ethnicity")]),
          _c("option", [_vm._v("Gender")]),
          _c("option", [_vm._v("Ell Status")]),
          _c("option", [_vm._v("Language Awarded")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "kt-input-icon__icon kt-input-icon__icon--left",
        staticStyle: { "z-index": "4" },
      },
      [_c("span", [_c("i", { staticClass: "la la-search" })])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }