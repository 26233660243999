<template>
<div class="tab-pane active show ">
    <div class="kt-widget28__tab-items">
        <div class="kt-widget28__tab-item pt-2 pb-2 kt-widget28__tab">
            <span>
                Messaging
            </span>
        </div>
    </div>
    <div class="pl-1 pr-1">
        <ChatGroups :is-for-panel="true" />
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Vue from 'vue';
import Types from '../../store/Types';
import ChatGroups from '../Communication/ChatGroups.vue';

export default Vue.extend({
    name: 'CommunicationPanel',
    components: {
        ChatGroups,
    },
    mixins: [],
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    methods: {
        toggleNewGroup() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_COMMUNICATION_GROUP);
        },
    },
});
</script>
