var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "student-details-panel tab-pane active show p-3" },
    [
      _c(
        "div",
        { staticClass: "image-header kt-widget kt-widget--user-profile-4" },
        [
          _c("div", { staticClass: "kt-widget__head" }, [
            _c(
              "div",
              { staticClass: "kt-widget__media" },
              [
                _c("StudentPortfolioAvatar", {
                  attrs: { student: _vm.student },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "kt-widget__content" }, [
              _c("div", { staticClass: "kt-widget__section" }, [
                !_vm.encryptionEnabled
                  ? _c(
                      "div",
                      { staticClass: "kt-widget__username pb-1 pt-4 w-100" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.student.lastName) +
                            ", " +
                            _vm._s(_vm.student.firstName) +
                            " "
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "kt-widget__username pb-1 pt-4 w-100" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.student.maskedStudentName) + " "
                        ),
                      ]
                    ),
                !_vm.isHomeUser &&
                _vm.administrativeBadges.length &&
                _vm.academicAwards.length
                  ? _c(
                      "div",
                      { staticClass: "badge-container text-center pt-4" },
                      [
                        _c("div", { staticClass: "text-muted d-none" }, [
                          _vm._v(" Badges & Awards "),
                        ]),
                        _vm._l(_vm.administrativeBadges, function (item) {
                          return _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              key: `badge_${item.badge.badgeId}`,
                              staticClass: "mx-1",
                              attrs: {
                                role: "button",
                                title: `${item.count}x ${item.badge.badgeTitle}`,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.showStudentBadgesRoute.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.badge.badgeIcon) + " ")]
                          )
                        }),
                        _vm._l(_vm.academicAwards, function (item) {
                          return _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              key: `badge_${item.badge.badgeId}`,
                              staticClass: "mx-1",
                              attrs: {
                                role: "button",
                                title: `${item.count}x ${item.badge.badgeTitle}`,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.showStudentBadgesRoute.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.badge.badgeIcon) + " ")]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "kt-widget__action my-3" },
                  [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottom",
                            value: `Create Anecdotal`,
                            expression: "`Create Anecdotal`",
                            modifiers: { hover: true, bottom: true },
                          },
                        ],
                        staticClass:
                          "btn btn-icon btn-circle btn-label-twitter m-1",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.newAnecdotal.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "la la-file-text",
                          staticStyle: { "font-size": "1.4rem !important" },
                        }),
                      ]
                    ),
                    _vm.hasBadges
                      ? _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.bottom",
                                value: `Add Award / Badge`,
                                expression: "`Add Award / Badge`",
                                modifiers: { hover: true, bottom: true },
                              },
                            ],
                            staticClass:
                              "btn btn-icon btn-circle btn-label-linkedin m-1",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.newStudentBadge.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "la la-smile-o",
                              staticStyle: { "font-size": "1.5rem !important" },
                            }),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isHomeUser && _vm.canMessageHomeUsers
                      ? _c(
                          "b-dropdown",
                          {
                            staticClass: "m-1",
                            attrs: {
                              "toggle-class": "px-0",
                              variant: "none",
                              "no-caret": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: `Create Message`,
                                              expression: "`Create Message`",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-icon btn-circle btn-label-google",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "la la-comments",
                                            staticStyle: {
                                              "font-size": "1.5rem !important",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1792617377
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  variant: "none",
                                  "button-class": "dropdown-item",
                                  "link-class": "kt-link",
                                },
                                on: { click: _vm.toggleCreateMessageToStudent },
                              },
                              [
                                _c("SVGIcon", {
                                  staticClass:
                                    "kt-svg-icon kt-svg-icon--sm mr-2",
                                  attrs: { name: "communication" },
                                }),
                                _vm._v(" Message Student "),
                              ],
                              1
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  variant: "none",
                                  "button-class": "dropdown-item",
                                  "link-class": "kt-link",
                                },
                                on: {
                                  click: _vm.toggleCreateMessageToGuardian,
                                },
                              },
                              [
                                _c("SVGIcon", {
                                  staticClass:
                                    "kt-svg-icon kt-svg-icon--sm mr-2",
                                  attrs: { name: "guardians" },
                                }),
                                _vm._v(" Message Guardian "),
                              ],
                              1
                            ),
                            _vm._t("toolbar-dropdown"),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pb-1 pt-1 w-100 text-center" },
                  [
                    _vm.extCourseSectionId
                      ? _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-label-brand btn-bold btn-upper",
                            attrs: {
                              to: {
                                name: "StudentCourseOverview",
                                params: {
                                  studentEnrollmentId:
                                    _vm.student.studentEnrollmentId,
                                  extCourseSectionId: _vm.extCourseSectionId,
                                },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-user" }),
                            _vm._v(" Open Portfolio "),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-label-brand btn-bold btn-upper",
                            attrs: {
                              to: {
                                name: "StudentInfo",
                                params: {
                                  studentEnrollmentId:
                                    _vm.student.studentEnrollmentId,
                                },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-user" }),
                            _vm._v(" Open Portfolio "),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "ul",
        {
          staticClass: "nav nav-tabs nav-tabs-line pt-3",
          attrs: { role: "tablist" },
        },
        [
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.selectedPane == "bio" },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.selectPane("bio")
                  },
                },
              },
              [_vm._v(" Biographical ")]
            ),
          ]),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.selectedPane == "courses" },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.selectPane("courses")
                  },
                },
              },
              [_vm._v(" Courses ")]
            ),
          ]),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: { active: _vm.selectedPane == "anecdotals" },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.selectPane("anecdotals")
                  },
                },
              },
              [_vm._v(" Anecdotals ")]
            ),
          ]),
        ]
      ),
      _vm.selectedPane == "anecdotals"
        ? _c(
            "div",
            { staticClass: "data-fields py-3 w-100" },
            [
              _c("StudentAnecdotalFilters", {
                attrs: { "student-id": _vm.student.studentId },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedPane == "courses"
        ? _c("StudentDetailCourseList", { attrs: { student: _vm.student } })
        : _vm._e(),
      _vm.selectedPane == "bio"
        ? _c(
            "div",
            {
              staticClass: "data-fields kt-widget28__tab-items py-3 row w-100",
            },
            [
              !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-4 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(0),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.extStudentId
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.student.extStudentId.substring(0, 3)) +
                              "-" +
                              _vm._s(_vm.student.extStudentId.substring(3, 6)) +
                              "-" +
                              _vm._s(_vm.student.extStudentId.substring(6, 9)) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "col-3 kt-widget28__tab-item kt-widget28__tab-link mt-0 py-3",
                },
                [
                  _vm._m(1),
                  _c("span", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.student.homeRoom) + " "),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-3 kt-widget28__tab-item kt-widget28__tab-link mt-0 py-3",
                },
                [
                  _vm._m(2),
                  _c("span", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.student.gradeLevel) + " "),
                  ]),
                ]
              ),
              _vm.encryptionEnabled
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "text-center pt-4" }, [
                      _vm._v(
                        " PII is currently encrypted. Please unlock to view. "
                      ),
                    ]),
                    _c("div", { staticClass: "text-center pt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-label-brand btn-bold btn-upper mt-4",
                          staticStyle: { "min-width": "160px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.decrypt()
                            },
                          },
                        },
                        [_vm._v(" Unlock PII ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.student.schoolEmail && !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-12 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(3),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.schoolEmail
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(" " + _vm._s(_vm.student.schoolEmail) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.$_userMixins_isGoogleDisabled &&
              (_vm.student.googleEmail || _vm.student.unlinkedGoogleEmail) &&
              !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-12 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(4),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.googleEmail ||
                                  _vm.student.unlinkedGoogleEmail
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.student.googleEmail ||
                                  _vm.student.unlinkedGoogleEmail
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.student.street &&
              _vm.student.primaryGuardian &&
              !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(5),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                `${_vm.student.primaryGuardian}\n ${_vm.student.street}, ${_vm.student.city} ${_vm.student.state} ${_vm.student.zip}`
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(
                            " " + _vm._s(_vm.student.primaryGuardian) + " "
                          ),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.student.street)),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.student.city) +
                              ", " +
                              _vm._s(_vm.student.state) +
                              " " +
                              _vm._s(_vm.student.zip) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.student.phoneNumber && !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(6),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.phoneNumber
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(" " + _vm._s(_vm.student.phoneNumber) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.student.homeLanguage && !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(7),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.homeLanguage
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(" " + _vm._s(_vm.student.homeLanguage) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.student.cohortYear
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(8),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.cohortYear
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(" " + _vm._s(_vm.student.cohortYear) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.student.adminDate && !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(9),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.adminDate
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(" " + _vm._s(_vm.student.adminDate) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.student.ethnicity && !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(10),
                      _c(
                        "a",
                        {
                          staticClass: "mb-0",
                          attrs: { href: "#", title: "Copy to Clipboard" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.copyTextToClipboard(
                                _vm.student.ethnicity
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-copy mr-1" }),
                          _vm._v(" " + _vm._s(_vm.student.ethnicity) + " "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(11),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.student.englishLearner ? "Yes" : "No")
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.encryptionEnabled
                ? _c(
                    "div",
                    { staticClass: "col-6 kt-widget28__tab-item mt-0 py-3" },
                    [
                      _vm._m(12),
                      _c("span", [
                        _vm._v(_vm._s(_vm.student.specialEd ? "Yes" : "No")),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Student Id ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Homeroom ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Grade Level ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" School Email ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Google Email ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Guardian ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Phone Number ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Home Language ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Entered 9th Grade ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Admission Date ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [_vm._v(" Ethnicity ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [
          _vm._v(" English Language Learner "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "mr-auto" }, [
          _vm._v(" Special Education Program "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }