var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "tab-pane active show" }, [
    _vm.assignment
      ? _c("div", { staticClass: "kt-widget28__tab-items" }, [
          _vm._m(0),
          _c(
            "h5",
            { staticClass: "kt-font-lg font Black mt-4 mb-0 ml-3 pb-4 pt-2" },
            [_vm._v(" " + _vm._s(_vm.assignment.courseWorkTitle) + " ")]
          ),
          _c("div", { staticClass: "row px-4 py-2" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Course")]),
                _c("span", [_vm._v(_vm._s(_vm.course.name))]),
              ]),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm.teacher
                ? _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [_vm._v("Teacher")]),
                    _c("span", [_vm._v(_vm._s(_vm.teacher.lastName))]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "row px-4 py-2" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Marking Period")]),
                _c("span", [_vm._v(_vm._s(_vm.markingPeriod.markingPeriod))]),
              ]),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Grading Category")]),
                _c("span", [_vm._v(_vm._s(_vm.assignment.categoryName))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row px-4 py-2" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Published")]),
                _c("span", [_vm._v(_vm._s(_vm.assignment.publishDate))]),
              ]),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Due")]),
                _c("span", [_vm._v(_vm._s(_vm.assignment.dueDate))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row px-4 py-2" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Points")]),
                _c("span", [_vm._v(_vm._s(_vm.assignment.maxPoints))]),
              ]),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "kt-widget28__tab-item" }, [
                _c("span", [_vm._v("Weight")]),
                _c("span", [_vm._v(_vm._s(_vm.assignment.courseWorkWeight))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row px-4 py-2" }, [
            _vm.assignment.courseWorkDomain == "Google"
              ? _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.assignment.alternateLink &&
                    _vm.assignment.googleCourseWorkId
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-google pull-right",
                            attrs: {
                              href: _vm.assignment.alternateLink,
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "gc",
                              attrs: {
                                src: "/images/google_classroom_mono.svg",
                              },
                            }),
                            _vm._v(" Open In Google "),
                          ]
                        )
                      : _vm._e(),
                    _vm.$_userMixins_isSchoolUser &&
                    _vm.assignment.associatedWithDeveloper
                      ? _c(
                          "router-link",
                          {
                            staticClass: "btn btn-primary pull-right mr-2",
                            attrs: {
                              to: {
                                name: "TeacherGoogleCourseAssignmentEdit",
                                params: {
                                  googleCourseWorkId:
                                    _vm.assignment.googleCourseWorkId,
                                  extCourseSectionId:
                                    _vm.course.extCourseSectionId,
                                  schoolEmail: _vm.teacher.schoolEmail,
                                },
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "gc",
                              attrs: { src: "/images/sync-grades_mono.svg" },
                            }),
                            _c("span", { staticStyle: { color: "#fff" } }, [
                              _vm._v(" Edit In SyncGrades "),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm.$_userMixins_isSchoolUser &&
                _vm.teacher &&
                _vm.teacher.schoolEmail == _vm.$_userMixins_schoolEmail
              ? _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-primary pull-right mr-2",
                        attrs: {
                          to: {
                            name: "TeacherLocalCourseAssignmentEdit",
                            params: {
                              courseWorkId: _vm.assignment.courseWorkId,
                              extCourseSectionId: _vm.course.extCourseSectionId,
                            },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "gc",
                          attrs: { src: "/images/sync-grades_mono.svg" },
                        }),
                        _c("span", { staticStyle: { color: "#fff" } }, [
                          _vm._v(" Edit In SyncGrades "),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "row px-4 py-2" }, [
            _c(
              "div",
              { staticClass: "col-12 pt-3 markdown-container" },
              [
                _vm.assignment.courseWorkDescription
                  ? _c("VueShowdown", {
                      staticClass: "markdown",
                      attrs: {
                        markdown: _vm.assignment.courseWorkDescription,
                        flavor: "github",
                        options: { emoji: true },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "kt-widget28__tab-item pt-2 pb-2 kt-widget28__tab" },
      [_c("span", [_vm._v(" Assignment Details ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }