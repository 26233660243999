var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "tab-pane active show" }, [
      _c("div", { staticClass: "kt-widget28__tab-items" }, [
        _c(
          "div",
          { staticClass: "kt-widget28__tab-item pt-2 pb-2 kt-widget28__tab" },
          [_c("span", [_vm._v(" Gradebook Settings ")])]
        ),
        _vm._v(" form shit "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }