var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.key,
      staticClass: "portfolio-links kt-widget28__tab-items py-3 w-100",
    },
    [
      _c("label", { staticClass: "kt-font-bold w-100 pb-3" }, [
        _vm._v(" Schedule "),
        _c(
          "span",
          { staticClass: "form-text text-muted pull-right mt-0" },
          _vm._l(_vm.days, function (day, idx) {
            return _c("span", { key: `day_${idx}` }, [
              _c(
                "a",
                {
                  class:
                    _vm.today == idx
                      ? "kt-link kt-link-active kt-font-dark"
                      : "kt-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.today = idx
                    },
                  },
                },
                [_vm._v(" " + _vm._s(day) + " ")]
              ),
              idx < _vm.days.length - 1
                ? _c("span", [_vm._v(" | ")])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "kt-notification" }, [
        _vm.todaysCourses.length == 0
          ? _c("div", { staticClass: "pb-3" }, [
              _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  " No courses on " +
                    _vm._s(_vm.days[_vm.today]) +
                    ", all courses are listed below. "
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "table",
          _vm._l(_vm.coursesWithPeriods, function (course, idx) {
            return _c(
              "tr",
              { key: `courseList_${idx}_${course.extCourseSectionId}` },
              [
                _c("td", [
                  _c("div", { staticClass: "media pt-1" }, [
                    course.period && course.day
                      ? _c(
                          "div",
                          {
                            staticClass: "mr-3",
                            staticStyle: { width: "70px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "course-title kt-font-bold kt-font-lg",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(course.day) +
                                    ", Pd " +
                                    _vm._s(course.period) +
                                    " "
                                ),
                              ]
                            ),
                            _c("br"),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v("Rm " + _vm._s(course.room)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "media-body" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "course-title kt-font-bold kt-font-lg",
                            attrs: {
                              to: {
                                name: "StudentCourseOverview",
                                params: {
                                  extCourseSectionId: course.extCourseSectionId,
                                  studentEnrollmentId: _vm.studentEnrollmentId,
                                },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(course.name) + " ")]
                        ),
                        course.teachers.length == 0
                          ? _c("div", { staticClass: "text-muted" }, [
                              _vm._v(" No Teachers "),
                            ])
                          : _c(
                              "div",
                              { staticClass: "text-muted" },
                              _vm._l(course.teachers, function (teacher) {
                                return _c(
                                  "router-link",
                                  {
                                    key: `${teacher.schoolStaffId}_teacher`,
                                    staticClass: "mr-3 kt-link kt-font-dark",
                                    attrs: {
                                      to: {
                                        name: "TeacherCourseInfo",
                                        params: {
                                          extCourseSectionId:
                                            course.extCourseSectionId,
                                          schoolEmail: teacher.schoolEmail,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(teacher.lastName) + " ")]
                                )
                              }),
                              1
                            ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "div",
                    {
                      class: `hstack row no-wrap hstack-marks-${course.studentMarkingPeriodAverages.length}mp`,
                    },
                    _vm._l(course.studentMarkingPeriodAverages, function (avg) {
                      return _c("StudentMarkingPeriodMark", {
                        key: `studentMarkingPeriodAverage_${avg.markingPeriod.schoolTermMarkingPeriodId}`,
                        staticClass: "marking-period-grade mr-2 py-2",
                        attrs: {
                          course: course,
                          "student-marking-period-average": avg,
                        },
                      })
                    }),
                    1
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }