var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "tab-pane active show" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "pl-1 pr-1" },
      [_c("ChatGroups", { attrs: { "is-for-panel": true } })],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget28__tab-items" }, [
      _c(
        "div",
        { staticClass: "kt-widget28__tab-item pt-2 pb-2 kt-widget28__tab" },
        [_c("span", [_vm._v(" Messaging ")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }