<template>
<div :key="key" class="portfolio-links kt-widget28__tab-items py-3 w-100">
    <label class="kt-font-bold w-100 pb-3">
        Schedule
        <span class="form-text text-muted pull-right mt-0">
            <span
                v-for="(day, idx) in days"
                :key="`day_${idx}`"
            >
                <a
                    href="#"
                    :class="today == idx ? 'kt-link kt-link-active kt-font-dark' : 'kt-link'"
                    @click.prevent="today = idx"
                >
                    {{ day }}
                </a>
                <span
                    v-if="idx < days.length - 1"
                > | </span>
            </span>
        </span>
    </label>
    <div class="kt-notification">
        <div
            v-if="todaysCourses.length == 0"
            class="pb-3"
        >
            <span class="text-muted">
                No courses on {{ days[today] }},
                all courses are listed below.
            </span>
        </div>


        <table>
            <tr
                v-for="(course, idx) in coursesWithPeriods"
                :key="`courseList_${idx}_${course.extCourseSectionId}`"
            >
                <td>
                    <!-- Period / Room -->
                    <div class="media pt-1">
                        <div
                            v-if="course.period && course.day"
                            style="width: 70px"
                            class="mr-3"
                        >
                            <span class="course-title kt-font-bold kt-font-lg">
                                {{ course.day }},
                                Pd {{ course.period }}
                            </span>
                            <br>
                            <span class="text-muted">Rm {{ course.room }}</span>
                        </div>
                        <div class="media-body">
                            <router-link
                                :to="{name: 'StudentCourseOverview', params: { extCourseSectionId: course.extCourseSectionId, studentEnrollmentId}}"
                                class="course-title kt-font-bold kt-font-lg"
                            >
                                {{ course.name }}
                            </router-link>
                            <div
                                v-if="course.teachers.length == 0"
                                class="text-muted"
                            >
                                No Teachers
                            </div>
                            <div
                                v-else
                                class="text-muted"
                            >
                                <router-link
                                    v-for="teacher in course.teachers"
                                    :key="`${teacher.schoolStaffId}_teacher`"
                                    :to="{name: 'TeacherCourseInfo', params: { extCourseSectionId: course.extCourseSectionId, schoolEmail: teacher.schoolEmail }}"
                                    class="mr-3 kt-link kt-font-dark"
                                >
                                    {{ teacher.lastName }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div :class="`hstack row no-wrap hstack-marks-${course.studentMarkingPeriodAverages.length}mp`">
                        <StudentMarkingPeriodMark
                            v-for="avg in course.studentMarkingPeriodAverages"
                            :key="`studentMarkingPeriodAverage_${avg.markingPeriod.schoolTermMarkingPeriodId}`"
                            class="marking-period-grade mr-2 py-2"

                            :course="course"
                            :student-marking-period-average="avg"
                        />
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
</template>
<script>
import { getCourses, CourseFilter } from '../../store/mixins/courseMixins';
import StudentMarkingPeriodMark from '../StudentMarkingPeriodMark.vue';
import moment from 'moment';
import Types from  '../../store/Types';
import { populate } from '../../store/mixins/portfolioMixins';

export default {
    name: 'StudentDetailCourseList',
    components: {
        StudentMarkingPeriodMark,
    },
    props: {
        student: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
            days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            today: 0,
            styleCourseIcon: {
                'font-size': '1rem', 'font-weight': 'normal',
            },
        };
    },
    computed: {
        studentEnrollmentId() {
            return this.student.studentEnrollmentId;
        },
        todaysCourses() {
            const { studentCourses, today, days } = this;
            return studentCourses
                .filter(course => course.courseMeetings)
                .filter(course => course.courseMeetings.some(meeting => meeting.day.substring(0, 3) === days[today]));
        },
        studentCourses() {
            const { studentEnrollmentId } = this;
            const { database } = this.$store.state;
            const filter = new CourseFilter({ studentEnrollmentId });
            const courses = getCourses(database, filter)
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
            return courses;
        },
        courses() {
            const { studentCourses, todaysCourses } = this;
            if (todaysCourses.length == 0) {
                return studentCourses;
            }
            return todaysCourses;
        },
        coursesWithPeriods() {
            const { courses, days, today } = this;
            return courses
                .map(function(course) {
                    const meeting = course.courseMeetings
                        .find(meeting => meeting.day.substring(0, 3) === days[today]);
                    return {
                        ...course,
                        day: meeting ? meeting.day.substring(0, 3) : null,
                        period: meeting ? meeting.period : null,
                        room: meeting ? meeting.room : null,
                    };
                });
        },
    },
    mounted() {
        const { showError } = this;
        this.today = moment().isoWeekday() - 1;
        const { courseSectionStudents, courseSections } = this.$store.state.database;
        if (courseSectionStudents.length == 0) {



            // this.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
            //     tableName: 'courseSectionStudents',
            //     callback: function(err) {
            //         if (err) showError(err);
            //         v.key += 1;
            //     }
            // });
            // this.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
            //     tableName: 'courseSections',
            //     callback: function(err) {
            //         if (err) showError(err);
            //         v.key += 1;
            //     }
            // });
        }

    },
    methods: {

    }
};
</script>
<style scoped>

tr td div.media {
    min-width: 240px;
}

tr td {
    vertical-align: middle !important;
}
.hstack-attendance {
    min-width: 240px;
}
.hstack-marks-1mp {
    width: 46px;
}
.hstack-marks-2mp {
    width: calc(2 * 46px);
}
.hstack-marks-3mp {
    width: calc(3 * 46px);
}
.hstack-marks-4mp {
    width: calc(4 * 46px);
}
.hstack-marks-5mp {
    width: calc(5 * 46px);
}
.hstack-marks-6mp {
    width: calc(6 * 46px);
}

div.media {
    min-width: 280px;
}
.marking-period-grade {
    min-width: 39px;
    max-width: 39px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
/* .active-marking-period {
    border-bottom: 1px solid #0f88ef;
} */

a.course-title:hover {
    color: #339bf3 !important;
}
a.course-title {
    min-width: 100px;
    max-width: 220px;
    padding-right: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.kt-widget29__content {
    background: transparent !important;
}

    .course-title{
        font-size: 1rem !important;
        font-weight: 400 !important;
        color: #74788d !important;
    }
    .course_item{
        font-size: 1.1rem;
        font-weight: 500;
        color: #595d6e;
    }
    .separator{
        border-bottom: 1px solid #ebedf2;
    }
</style>

<style>
div.portfolio-links .course-icon {
    color: rgba(255, 255, 255, 0.8);
}

div.portfolio-links div.b-popover {
    z-index: 100 !important;
}
</style>
