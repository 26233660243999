<template>
<div class="tab-pane active show ">
    <div class="kt-widget28__tab-items">
        <div class="kt-widget28__tab-item pt-2 pb-2 kt-widget28__tab">
            <span>
                Gradebook Settings
            </span>
        </div>
        form shit
    </div>
</div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'GradebookSettingsPanel',
    computed: {},
});

</script>

<style scoped>
div.panel-body {
    height: 100vh;
}
</style>
